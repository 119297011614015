@charset "UTF-8";


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

@import url("https://p.typekit.net/p.css?s=1&k=ayf4kke&ht=tk&f=24539.24547.37520.37522&a=34385662&app=typekit&e=css");

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/02ad94/00000000000000003b9b46f3/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4cc789/00000000000000003b9b46ed/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"poppins";
  src:url("https://use.typekit.net/af/aa2064/00000000000000003b9b137c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/aa2064/00000000000000003b9b137c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/aa2064/00000000000000003b9b137c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
  font-family:"poppins";
  src:url("https://use.typekit.net/af/b45ee0/00000000000000003b9b137e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/b45ee0/00000000000000003b9b137e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/b45ee0/00000000000000003b9b137e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

.tk-sofia-pro { font-family: "sofia-pro",sans-serif; }
.tk-poppins { font-family: "poppins",sans-serif; }

:root {
  --surface-a:#ffffff;
  --surface-b:#f8f9fa;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --gray-100:#F6F7FB;
  --gray-200:#EDEDF1;
  --gray-300:#DBDAE3;
  --gray-400:#C9C8D4;
  --gray-500:#A5A3B8;
  --gray-600:#6E6B8B;
  --gray-700:#4E4B75;
  --gray-800:#373361;
  --gray-900:#212121;
  --text-color:#4E4B75;
  --text-color-secondary:#ffffff;
  --primary-color:#817CCD;
  --secondary-color:#41B3FF;
  --danger-color:#41B3FF;
  --warning-color:#41B3FF;
  --success-color:#41B3FF;
  --help-color:#41B3FF;
  --font-family:"sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Responsive Breakpoints */
* {
  box-sizing: border-box;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-component {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.7;
}
.p-disabled *, .p-component:disabled * {
  pointer-events: none;
}

.p-error, .p-invalid {
  color: #F65F5F;
}

.p-text-secondary {
  color: #ffffff;
}

.pi {
  font-size: 14px;
}

.p-link {
  font-size: 1rem;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 10px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-autocomplete .p-autocomplete-loader {
  right: 20px;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 60px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 10px 20px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #b6b3e2;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 10px 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #4E4B75;
  padding: 0;
  margin: 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 10px 20px;
  margin-right: 0.5rem;
  background: #ecebf7;
  color: #817CCD;
  border-radius: 10px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: #F65F5F;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #F65F5F;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 10px 10px;
  border: 0 none;
  color: #4E4B75;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #4E4B75;
  background: #ecebf7;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: #F65F5F;
}
.p-calendar.p-calendar-w-btn {
  border: 2px solid #F6F7FB;
  border-radius: 10px;
}
.p-calendar.p-calendar-w-btn input {
  border: none;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #F65F5F;
}

.p-datepicker {
  padding: 10px;
  background: #ffffff;
  color: #4E4B75;
  border: 2px solid #F6F7FB;
  border-radius: 10px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 10px;
  color: #4E4B75;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 2px solid #EDEDF1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 5px 0;
}
.p-datepicker table th {
  padding: 5px;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 5px;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #EDEDF1;
  color: #4E4B75;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #C9C8D4;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #C9C8D4;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.286rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.429rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 5px 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 5px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #C9C8D4;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #c8c6e9;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #c8c6e9;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 3px;
  }
}
.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #EDEDF1;
  background: #F6F7FB;
  width: 20px;
  height: 20px;
  color: #4E4B75;
  border-radius: 5px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  position: relative;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #817CCD;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #b6b3e2;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #817CCD;
  background: #ffffff;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #817CCD;
  background: #ffffff;
  color: #817CCD;
}
.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
  /*font-family: cov-icons;*/
  font-family: "Material Design Icons";
}
.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon:before {
  content: "\F0130";
}
.p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #F65F5F;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #F65F5F;
}

.p-checkbox-filled .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #ffffff;
}
.p-checkbox-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #c8c6e9;
  color: #ffffff;
}
.p-checkbox-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #817CCD;
  color: #ffffff;
}
.p-checkbox-filled .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background: #817CCD;
  border-color: #817CCD;
}
.p-checkbox-filled.p-checkbox-secondary .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #ffffff;
}
.p-checkbox-filled.p-checkbox-secondary .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #a7dcff;
  color: #ffffff;
}
.p-checkbox-filled.p-checkbox-secondary .p-checkbox .p-checkbox-box.p-highlight {
  background: #41B3FF;
  color: #ffffff;
  border-color: #41B3FF;
}
.p-checkbox-filled.p-checkbox-secondary .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background: #41B3FF;
  border-color: #41B3FF;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #ffffff;
}
.p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #ffffff;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background: #ffffff;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 10px 20px;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #b6b3e2;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 10px 20px;
  margin-right: 0.5rem;
  background: #ecebf7;
  color: #817CCD;
  border-radius: 10px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-label {
  font-size: 1rem;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 10px 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #4E4B75;
  padding: 0;
  margin: 0;
}
.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: #F65F5F;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #F65F5F;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #373361;
  border-color: #1F1C41;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.p-dropdown {
  background: #F6F7FB;
  border: 2px solid #F6F7FB;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #b6b3e2;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 34px;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #A5A3B8;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #4E4B75;
  width: 2.357rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #4E4B75;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 10px 10px;
  border-bottom: 0px solid #F6F7FB;
  color: #4E4B75;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding: 20px 20px/2;
  padding-right: 34px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 20px;
  color: #4E4B75;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
  padding: 0 0;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 10px 10px;
  border: 0 none;
  color: #4E4B75;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #4E4B75;
  background: #ecebf7;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #4E4B75;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel.p-error, .p-dropdown-panel.p-invalid {
  border-color: #F65F5F;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #F65F5F;
}

.p-input-filled .p-dropdown {
  background: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 2px solid #EDEDF1;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #4E4B75;
  padding: 10px 10px;
  border-radius: 10px;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #4E4B75;
  background: #ecebf7;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 2px solid #EDEDF1;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #F6F7FB;
  color: #4E4B75;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #817CCD;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #817CCD;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #817CCD;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #817CCD;
}

.p-inputgroup-addon {
  background: #ffffff;
  color: #4E4B75;
  border-top: 2px solid #F6F7FB;
  border-left: 2px solid #F6F7FB;
  border-bottom: 2px solid #F6F7FB;
  padding: 20px 20px;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 2px solid #F6F7FB;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 40px;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #F65F5F;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #F65F5F;
}

.p-inputnumber {
  border: 2px solid #F6F7FB;
  border-radius: 10px;
}
.p-inputnumber input {
  border: none;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #DBDAE3;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #C9C8D4;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #817CCD;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #6f6ac6;
}
.p-inputswitch.p-error, .p-inputswitch.p-invalid {
  border-color: #F65F5F;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #F65F5F;
}

.p-inputtext {
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #4E4B75;
  background: #F6F7FB;
  padding: 20px 20px;
  border: 2px solid #F6F7FB;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 10px;
  width: 100%;
}
.p-inputtext:enabled:hover {
  border-color: #b6b3e2;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-inputtext.p-error, .p-inputtext.p-invalid, .p-inputtext.ng-dirty.ng-invalid {
  border-color: #F65F5F;
}
.p-inputtext.p-inputtext-sm {
  padding: 5px 5px;
}
.p-inputtext.p-inputtext-lg {
  font-size: 20px;
  padding: 25px 25px;
}

.p-float-label > label {
  left: 20px;
  color: #A5A3B8;
  transition-duration: 0.2s;
}

.p-input-icon-left > i:first-of-type {
  left: 20px;
  color: #4E4B75;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 54px;
}

.p-input-icon-right > i:last-of-type {
  right: 20px;
  color: #4E4B75;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 54px;
}

::-webkit-input-placeholder {
  color: #A5A3B8;
}

:-moz-placeholder {
  color: #A5A3B8;
}

::-moz-placeholder {
  color: #A5A3B8;
}

:-ms-input-placeholder {
  color: #A5A3B8;
}

.p-input-filled .p-inputtext {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ffffff;
  border: none;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
  border: none;
}

.p-inputtext-sm .p-inputtext {
  padding: 5px 5px;
}

.p-inputtext-lg .p-inputtext {
  padding: 25px 25px;
}

.p-listbox {
  background: #ffffff;
  color: #4E4B75;
  border: 2px solid #F6F7FB;
  border-radius: 10px;
  overflow: hidden;
}
.p-listbox .p-listbox-header {
  padding: 10px 10px;
  border-bottom: 0px solid #F6F7FB;
  color: #4E4B75;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding: 10px;
  padding-right: 24px;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 10px;
  color: #4E4B75;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 10px 10px;
  border: 0 none;
  color: #4E4B75;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #4E4B75;
  background: #ecebf7;
}
.p-listbox.p-error, .p-listbox.p-invalid {
  border-color: #F65F5F;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #F65F5F;
}

.p-multiselect {
  background: #F6F7FB;
  border: 2px solid #F6F7FB;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #b6b3e2;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-multiselect .p-multiselect-label {
  padding: 20px 20px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #A5A3B8;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #4E4B75;
  width: 2.357rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-multiselect.p-error, .p-multiselect.p-invalid {
  border-color: #F65F5F;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 10px 10px;
  border-bottom: 0px solid #F6F7FB;
  color: #4E4B75;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 34px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 20px;
  color: #4E4B75;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 10px 10px;
  border: 0 none;
  color: #4E4B75;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #4E4B75;
  background: #ecebf7;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #F65F5F;
}

.p-input-filled .p-multiselect {
  background: #ffffff;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-password-panel {
  padding: 20px;
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #EDEDF1;
  background: #F6F7FB;
  width: 20px;
  height: 20px;
  color: #4E4B75;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #b6b3e2;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #817CCD;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: #817CCD;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #817CCD;
  background: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #817CCD;
  background: #ffffff;
  color: #817CCD;
}
.p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #F65F5F;
}

.p-radiobutton-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #817CCD;
}
.p-radiobutton-filled .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #ffffff;
}
.p-radiobutton-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #817CCD;
  background: #817CCD;
}
.p-radiobutton-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #817CCD;
  color: #ffffff;
}
.p-radiobutton-filled.p-radiobutton-secondary .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #41B3FF;
}
.p-radiobutton-filled.p-radiobutton-secondary .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #ffffff;
}
.p-radiobutton-filled.p-radiobutton-secondary .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #41B3FF;
  background: #41B3FF;
}
.p-radiobutton-filled.p-radiobutton-secondary .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #41B3FF;
  color: #ffffff;
}

.p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #F65F5F;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #ffffff;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #c8c6e9 !important;
  margin-left: 3.5px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 14px;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #F65F5F !important;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.rating-on {
  color: #817CCD !important;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #817CCD !important;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #e20d0d !important;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #4E4B75;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #ecebf7;
  border-color: #C9C8D4;
  color: #4E4B75;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #4E4B75;
}
.p-selectbutton .p-button.p-highlight {
  background: #817CCD;
  border-color: #817CCD;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #6f6ac6;
  border-color: #6f6ac6;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: #F65F5F;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #F65F5F;
}

.p-slider {
  background: #EDEDF1;
  border: 0 none;
  border-radius: 100px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #817CCD;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-slider .p-slider-range {
  background: #817CCD;
  border-radius: 100px;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #817CCD;
  border-color: #817CCD;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #4E4B75;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #ecebf7;
  border-color: #C9C8D4;
  color: #4E4B75;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #4E4B75;
}
.p-togglebutton.p-button.p-highlight {
  background: #817CCD;
  border-color: #817CCD;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #6f6ac6;
  border-color: #6f6ac6;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-error, .p-togglebutton.p-button.p-invalid {
  border-color: #F65F5F;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #F65F5F;
}

.p-button {
  color: #ffffff;
  background: #817CCD;
  border: 1px solid #817CCD;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 700;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-button:enabled:hover {
  background: #817CCD;
  color: #ffffff;
  border-color: #817CCD;
  box-shadow: 0 4px 10px 0 rgba(129, 124, 205, 0.5);
}
.p-button:enabled:active {
  background: #6f6ac6;
  color: #ffffff;
  border-color: #6f6ac6;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #817CCD;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(129, 124, 205, 0.1);
  color: #817CCD;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(129, 124, 205, 0.16);
  color: #817CCD;
  border: 1px solid;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #817CCD;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(129, 124, 205, 0.1);
  color: #817CCD;
  border-color: transparent;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-text:enabled:active {
  background: rgba(129, 124, 205, 0.16);
  color: #817CCD;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #817CCD;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 40px;
  padding: 10px 25px;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 40px;
  padding: 10px;
}
.p-button.p-button-sm {
  padding: 3.75px 6.25px;
}
.p-button.p-button-lg {
  padding: 18.75px 31.25px;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 40px;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #41B3FF;
  border: 1px solid #41B3FF;
}
.p-button.p-button-light, .p-buttonset.p-button-light> .p-button, .p-splitbutton.p-button-light > .p-button {
  color: #616161;
  background: #ffffff;
  border: 1px solid #e8e8e8;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #41B3FF;
  color: #ffffff;
  border-color: #41B3FF;
  box-shadow: 0 4px 10px 0 rgba(65, 179, 255, 0.5);
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #28a9ff;
  color: #ffffff;
  border-color: #28a9ff;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #41B3FF;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(65, 179, 255, 0.1);
  color: #41B3FF;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(65, 179, 255, 0.16);
  color: #41B3FF;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #41B3FF;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(65, 179, 255, 0.1);
  border-color: transparent;
  color: #41B3FF;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(65, 179, 255, 0.16);
  border-color: transparent;
  color: #41B3FF;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #4E4B75;
  border: 1px solid #4E4B75;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #4E4B75;
  color: #ffffff;
  border-color: #4E4B75;
  box-shadow: 0 4px 10px 0 rgba(78, 75, 117, 0.5);
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #444165;
  color: #ffffff;
  border-color: #444165;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #4E4B75;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(78, 75, 117, 0.1);
  color: #4E4B75;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(78, 75, 117, 0.16);
  color: #4E4B75;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #4E4B75;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(78, 75, 117, 0.1);
  border-color: transparent;
  color: #4E4B75;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(78, 75, 117, 0.16);
  border-color: transparent;
  color: #4E4B75;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #5BD985;
  border: 1px solid #5BD985;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #5BD985;
  color: #ffffff;
  border-color: #5BD985;
  box-shadow: 0 4px 10px 0 rgba(91, 217, 133, 0.5);
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #46d476;
  color: #ffffff;
  border-color: #46d476;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #5BD985;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(91, 217, 133, 0.1);
  color: #5BD985;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(91, 217, 133, 0.16);
  color: #5BD985;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #5BD985;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(91, 217, 133, 0.1);
  border-color: transparent;
  color: #5BD985;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(91, 217, 133, 0.16);
  border-color: transparent;
  color: #5BD985;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #ffffff;
  background: #FFCC56;
  border: 1px solid #FFCC56;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #FFCC56;
  color: #ffffff;
  border-color: #FFCC56;
  box-shadow: 0 4px 10px 0 rgba(255, 204, 86, 0.5);
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #ffc43d;
  color: #ffffff;
  border-color: #ffc43d;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FFCC56;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 204, 86, 0.1);
  color: #FFCC56;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 204, 86, 0.16);
  color: #FFCC56;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #FFCC56;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 204, 86, 0.1);
  border-color: transparent;
  color: #FFCC56;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 204, 86, 0.16);
  border-color: transparent;
  color: #FFCC56;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #5F82F6;
  border: 1px solid #5F82F6;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #5F82F6;
  color: #ffffff;
  border-color: #5F82F6;
  box-shadow: 0 4px 10px 0 rgba(95, 130, 246, 0.5);
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #476ff5;
  color: #ffffff;
  border-color: #476ff5;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #5F82F6;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(95, 130, 246, 0.1);
  color: #5F82F6;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(95, 130, 246, 0.16);
  color: #5F82F6;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #5F82F6;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(95, 130, 246, 0.1);
  border-color: transparent;
  color: #5F82F6;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(95, 130, 246, 0.16);
  border-color: transparent;
  color: #5F82F6;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #F65F5F;
  border: 1px solid #F65F5F;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #F65F5F;
  color: #ffffff;
  border-color: #F65F5F;
  box-shadow: 0 4px 10px 0 rgba(246, 95, 95, 0.5);
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #f54747;
  color: #ffffff;
  border-color: #f54747;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #F65F5F;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(246, 95, 95, 0.1);
  color: #F65F5F;
  border: 1px solid;
  box-shadow: none;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(246, 95, 95, 0.16);
  color: #F65F5F;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #F65F5F;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(246, 95, 95, 0.1);
  border-color: transparent;
  color: #F65F5F;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(246, 95, 95, 0.16);
  border-color: transparent;
  color: #F65F5F;
}

.p-button.p-button-link {
  color: #5d57bf;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #5d57bf;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #5d57bf;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #ecebf7;
  color: #817CCD;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #ffffff;
  color: #4E4B75;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 0rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #EDEDF1;
  color: #4E4B75;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 1.4286rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1.4286rem 1.4286rem;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  font-weight: 600;
  color: #4E4B75;
  background: #F6F7FB;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-thead > tr:first-of-type > th:first-of-type {
  border-radius: 10px 0 0 0;
}
.p-datatable .p-datatable-thead > tr:first-of-type > th:last-of-type {
  border-radius: 0 10px 0 0;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1.4286rem 1.4286rem;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  font-weight: 600;
  color: #4E4B75;
  background: #F6F7FB;
}
.p-datatable .p-sortable-column {
  outline: none;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #4E4B75;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #817CCD;
  background: #ecebf7;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #4E4B75;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #817CCD;
  color: #ffffff;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #4E4B75;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #817CCD;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #EDEDF1;
  border-width: 0 0 1px 0;
  padding: 1.4286rem 1.4286rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #ecebf7;
  color: #817CCD;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #ecebf7;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #ecebf7;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-datatable .p-column-resizer-helper {
  background: #817CCD;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #F6F7FB;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 0px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #ecebf7;
  color: #817CCD;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #817CCD;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #817CCD;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.7143rem 0rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.7143rem 0.7143rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.7143rem 0.7143rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.7143rem 0.7143rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.7143rem 0.7143rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.78575rem 0rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.78575rem 1.78575rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.78575rem 1.78575rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.78575rem 1.78575rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.78575rem 1.78575rem;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #ffffff;
  color: #4E4B75;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 0rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #EDEDF1;
  color: #4E4B75;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 1.4286rem;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 20px;
}

.fc .fc-view-container th {
  background: #F6F7FB;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
}
.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
}
.fc .fc-view-container td.fc-head-container {
  border: 1px solid #C9C8D4;
}
.fc .fc-view-container .fc-row {
  border-right: 1px solid #C9C8D4;
}
.fc .fc-view-container .fc-event {
  background: #6f6ac6;
  border: 1px solid #6f6ac6;
  color: #ffffff;
}
.fc .fc-view-container .fc-divider {
  background: #F6F7FB;
  border: 1px solid #C9C8D4;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #817CCD;
  border: 1px solid #817CCD;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:hover {
  background: #817CCD;
  color: #ffffff;
  border-color: #817CCD;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 14px;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 14px;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #ecebf7;
  border-color: #C9C8D4;
  color: #4E4B75;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #817CCD;
  border-color: #817CCD;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #6f6ac6;
  border-color: #6f6ac6;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-orderlist .p-orderlist-controls {
  padding: 20px;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #F6F7FB;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  padding: 20px;
  border-bottom: 0 none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #C9C8D4;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 34px;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 20px;
  color: #4E4B75;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 0 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 10px 10px;
  margin: 0;
  border: 0 none;
  color: #4E4B75;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #a8a5dd;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 20px;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #ecebf7;
  color: #817CCD;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #938ed4;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #C9C8D4;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #C9C8D4;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 20px;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-paginator {
  background: #ffffff;
  color: #4E4B75;
  border: none;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #4E4B75;
  min-width: 40px;
  height: 40px;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #ecebf7;
  border-color: transparent;
  color: #4E4B75;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #4E4B75;
  min-width: 40px;
  height: 40px;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #4E4B75;
  min-width: 40px;
  height: 40px;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ecebf7;
  border-color: #ecebf7;
  color: #817CCD;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #ecebf7;
  border-color: transparent;
  color: #4E4B75;
}

.p-picklist .p-picklist-buttons {
  padding: 20px;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #F6F7FB;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  padding: 20px;
  border-bottom: 0 none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #C9C8D4;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 34px;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 20px;
  color: #4E4B75;
}
.p-picklist .p-picklist-list {
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 0 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 10px 10px;
  margin: 0;
  border: 0 none;
  color: #4E4B75;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #817CCD;
  background: #ecebf7;
}
.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #a8a5dd;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 10px 10px;
  color: #4E4B75;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 20px;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "";
  }
}
.p-tree {
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 20px;
  border-radius: 10px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #4E4B75;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #ecebf7;
  color: #817CCD;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #817CCD;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 34px;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 20px;
  color: #4E4B75;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #a8a5dd;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 10px;
  border: 1px solid #C9C8D4;
  background-color: #ffffff;
  color: #4E4B75;
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #ecebf7;
  color: #817CCD;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #817CCD;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #ffffff;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-checkbox-icon {
  color: #817CCD;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #ffffff;
  color: #4E4B75;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 0rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #EDEDF1;
  color: #4E4B75;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 1.4286rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1.4286rem 1.4286rem;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  font-weight: 600;
  color: #4E4B75;
  background: #F6F7FB;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1.4286rem 1.4286rem;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  font-weight: 600;
  color: #4E4B75;
  background: #F6F7FB;
}
.p-treetable .p-sortable-column {
  outline-color: #817CCD;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #4E4B75;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #817CCD;
  background: #ecebf7;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #4E4B75;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #817CCD;
  color: #ffffff;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #4E4B75;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #817CCD;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #EDEDF1;
  border-width: 0 0 1px 0;
  padding: 1.4286rem 1.4286rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #4E4B75;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #ecebf7;
  color: #817CCD;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #817CCD;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #817CCD;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #ecebf7;
  color: #4E4B75;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #4E4B75;
}
.p-treetable .p-column-resizer-helper {
  background: #817CCD;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #F6F7FB;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.35715rem 0rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.7143rem 0.7143rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.7143rem 0.7143rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.7143rem 0.7143rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.7143rem 0.7143rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.78575rem 0rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.78575rem 1.78575rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.78575rem 1.78575rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.78575rem 1.78575rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.78575rem 1.78575rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #ffffff;
  color: #4E4B75;
  border: 0px solid #C9C8D4;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 0rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #EDEDF1;
  color: #4E4B75;
  border: 0px solid #e9ecef;
  border-width: 0 0 0px 0;
  padding: 1.4286rem 1.4286rem;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 20px;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  background: #F6F7FB;
  font-weight: 600;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #ecebf7;
  border-color: 1px solid #C9C8D4;
  color: #4E4B75;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #F6F7FB;
  border-color: #C9C8D4;
  color: #4E4B75;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #C9C8D4;
  background: #ecebf7;
  color: #4E4B75;
}
.p-accordion .p-accordion-content {
  padding: 20px;
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

p-card.p-disabled .p-card {
  opacity: 0.7;
}
p-card.highlight .p-card {
  border: 2px solid #817CCD;
  box-shadow: 0 2px 10px 0 rgba(129, 124, 205, 0.5);
}
p-card.highlight .p-card * {
  color: #817CCD;
}

.p-card {
  background: #ffffff;
  color: #4E4B75;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  border: 2px solid #ffffff;
  border-radius: 10px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  position: relative;
  padding: 20px;
}
@media (min-width: 992px) {
  .p-card {
    padding: 40px;
  }
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card:not(.p-disabled).highlight, .p-card:not(.p-disabled).hover:hover {
  border: 2px solid #817CCD;
  box-shadow: 0 2px 10px 0 rgba(129, 124, 205, 0.5);
}
.p-card:not(.p-disabled).highlight *, .p-card:not(.p-disabled).hover:hover * {
  color: #817CCD;
}
.p-card:not(.p-disabled).hover {
  cursor: pointer;
}
.p-card.p-card-full-h {
  height: 100%;
}
.p-card.p-card-full-h .p-card-body {
  height: 100%;
}
.p-card.p-card-full-h .p-card-content {
  height: 100%;
}
.p-card.p-card-full-w {
  width: 100%;
}
.p-card.p-card-full-w .p-card-body {
  width: 100%;
}
.p-card.p-card-full-w .p-card-content {
  width: 100%;
}

.p-fieldset {
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  border-radius: 10px;
  padding: 20px;
}
.p-fieldset .p-fieldset-legend {
  padding: 20px;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  background: #F6F7FB;
  font-weight: 600;
  border-radius: 10px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 20px;
  color: #4E4B75;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #ecebf7;
  border-color: #C9C8D4;
  color: #4E4B75;
}

.p-panel .p-panel-header {
  border: 1px solid #C9C8D4;
  padding: 20px;
  background: #F6F7FB;
  color: #4E4B75;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 15px;
}
.p-panel .p-panel-content {
  padding: 20px;
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 20px;
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #c8c6e9;
  border: 0 none;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #C9C8D4;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #C9C8D4 transparent;
  background: #ffffff;
  color: #4E4B75;
  padding: 20px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #A5A3B8;
  color: #4E4B75;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #817CCD;
  color: #817CCD;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 20px;
  border: 0 none;
  color: #4E4B75;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-toolbar {
  background: #F6F7FB;
  border: 1px solid #C9C8D4;
  padding: 20px;
  border-radius: 10px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-dialog {
  border-radius: 10px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 20px 20px 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #4E4B75;
  padding: 20px 20px;
}
.p-dialog .p-dialog-footer {
  border-top: 0px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  padding: 0 20px 20px;
  text-align: right;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 28px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 20px;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #817CCD;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #817CCD;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #4E4B75;
  padding: 20px;
  border: 0 none;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
}
.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #4E4B75;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
  color: #ffffff;
  border-color: transparent;
  background: #817CCD;
}
.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #4E4B75;
  color: #ffffff;
  padding: 5px 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #4E4B75;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #4E4B75;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #4E4B75;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #4E4B75;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #F6F7FB;
  padding: 20px;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  border-bottom: 0 none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1.4286rem 1.4286rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #817CCD;
  color: #ffffff;
  border-color: #817CCD;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #6f6ac6;
  color: #ffffff;
  border-color: #6f6ac6;
}

.p-breadcrumb {
  font-size: 14px;
  color: #A5A3B8;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #A5A3B8;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #A5A3B8;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #A5A3B8;
}
.p-breadcrumb ul li.p-breadcrumb-chevron:before {
  font-size: 10px;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #A5A3B8;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #A5A3B8;
}

.p-contextmenu {
  padding: 10px 0;
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 150px;
}
.p-contextmenu .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-contextmenu .p-submenu-list {
  padding: 10px 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #ecebf7;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  border-radius: 10px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #4E4B75;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #4E4B75;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 10px 0;
  width: 150px;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #ecebf7;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-megamenu.p-megamenu-vertical {
  width: 150px;
  padding: 10px 0;
}

.p-menu {
  padding: 10px 0;
  background: #ffffff;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  border-radius: 10px;
  width: 150px;
  overflow: hidden;
}
.p-menu .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #4E4B75;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  border-radius: 10px;
}
.p-menubar .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-menubar .p-submenu-list {
  padding: 10px 0;
  background: #ffffff;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 150px;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #ecebf7;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #4E4B75;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #4E4B75;
    background: #ecebf7;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 10px 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #C9C8D4;
    margin: 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 10px 20px;
    color: #4E4B75;
    font-weight: normal;
    border-radius: 0;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #4E4B75;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #4E4B75;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #4E4B75;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #ecebf7;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #4E4B75;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #4E4B75;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #4E4B75;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 30px;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 50px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 70px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 90px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 110px;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 20px;
  border: 1px solid #C9C8D4;
  color: #4E4B75;
  background: #F6F7FB;
  font-weight: 600;
  border-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #ecebf7;
  border-color: 1px solid #C9C8D4;
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #F6F7FB;
  border-color: #C9C8D4;
  color: #4E4B75;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #C9C8D4;
  background: #ecebf7;
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content {
  padding: 10px 0;
  border: 1px solid #C9C8D4;
  background: #ffffff;
  color: #4E4B75;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-slidemenu {
  padding: 10px 0;
  background: #ffffff;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  border-radius: 10px;
  width: 150px;
}
.p-slidemenu .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 10px 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #ecebf7;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #4E4B75;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #4E4B75;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 10px 20px;
  color: #4E4B75;
}

.p-steps .p-steps-item {
  justify-content: flex-start;
}
.p-steps .p-steps-item.p-steps-complete .p-steps-number {
  background: #817CCD;
}
.p-steps .p-steps-item.p-steps-complete:before {
  background: #817CCD;
}
.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
  overflow: visible;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #ffffff;
  background: #C9C8D4;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  transition: background 0.2s ease-in-out;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number:before {
  content: "";
  width: 28px;
  height: 28px;
  border: solid 2px #C9C8D4;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: border-color, transform 0.2s ease-in-out;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #817CCD;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-number:before {
  border-color: #817CCD;
  transform: translate(-50%, -50%) scale(1.25);
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
}
.p-steps .p-steps-item:before {
  content: " ";
  height: 4px;
  width: 100%;
  background: #C9C8D4;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  transform: translateY(-50%);
}
.p-steps .p-steps-item:last-of-type {
  flex: 0 1 auto;
}
.p-steps .p-steps-item:last-of-type:before {
  display: none;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #C9C8D4;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #C9C8D4;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #C9C8D4 transparent;
  background: #ffffff;
  color: #4E4B75;
  padding: 20px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #A5A3B8;
  color: #4E4B75;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #817CCD;
  color: #817CCD;
}

.p-tieredmenu {
  padding: 10px 0;
  background: #ffffff;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  border-radius: 10px;
  width: 150px;
}
.p-tieredmenu .p-menuitem-link {
  padding: 10px 20px;
  color: #4E4B75;
  font-weight: normal;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #4E4B75;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #ecebf7;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 10px 0;
  background: #ffffff;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #ecebf7;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #4E4B75;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #4E4B75;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #C9C8D4;
  margin: 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 20px 20px;
  margin: 0;
  border-radius: 10px;
}
.p-inline-message.p-inline-message-info {
  background: #4E4B75;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-success {
  background: #5BD985;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-warn {
  background: #FFCC56;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-error {
  background: #F65F5F;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 10px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-message.p-message-info {
  background: #4E4B75;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-info .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-info .p-message-close {
  color: #ffffff;
}
.p-message.p-message-success {
  background: #5BD985;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-success .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-success .p-message-close {
  color: #ffffff;
}
.p-message.p-message-warn {
  background: #FFCC56;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-warn .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-warn .p-message-close {
  color: #ffffff;
}
.p-message.p-message-error {
  background: #F65F5F;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-error .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-error .p-message-close {
  color: #ffffff;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 4px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #4E4B75;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #5BD985;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFCC56;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #F65F5F;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #ffffff;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #ebedef;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ebedef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.2);
  color: #aeb6bf;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
  margin: 0.5rem 0;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #ebedef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #ebedef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #ecebf7;
  color: #817CCD;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #ecebf7;
  color: #817CCD;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #aeb6bf;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #aeb6bf;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-inplace .p-inplace-display {
  padding: 20px 20px;
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #4E4B75;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 10px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #817CCD;
}
.p-progressbar .p-progressbar-label {
  color: #4E4B75;
  line-height: 1.5rem;
}

.p-terminal {
  background: #ffffff;
  color: #4E4B75;
  border: 1px solid #C9C8D4;
  padding: 20px;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-badge {
  background: #ecebf7;
  color: #817CCD;
  font-size: 16px;
  font-weight: 400;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #41B3FF;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #5BD985;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #4E4B75;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #FFCC56;
  color: #ffffff;
}
.p-badge.p-badge-danger {
  background-color: #F65F5F;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 24px;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 32px;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-tag {
  background: #ecebf7;
  color: #817CCD;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 5px;
}
.p-tag.p-tag-success {
  background-color: #5BD985;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #0099CC;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #FFCC56;
  color: #ffffff;
}
.p-tag.p-tag-danger {
  background-color: #F65F5F;
  color: #ffffff;
}

/* Customizations to the designer theme should be defined here */
html, body {
  margin: 0;
  padding: 0;
  background-color: #F6F7FB;
  color: #4E4B75;
  font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 26px;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
}

.container {
  padding: 0 15px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.section {
  margin-bottom: 120px;
  position: relative;
}

.separator {
  height: 1px;
  width: 100%;
  background: #EDEDF1;
  border-radius: 50%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Poppins, sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}
@media (min-width: 768px) {
  h1 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 36px;
    line-height: 48px;
  }
}

h2 {
  font-size: 22px;
  line-height: 30px;
  margin: 0 0 20px 0;
}
@media (min-width: 768px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 40px 0;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

h3 {
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px 0;
}
@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 992px) {
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: normal;
  margin: 0 0 5px 0;
}
@media (min-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 20px 0;
  }
}

h5 {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  margin: 0 0 10px 0;
}
@media (min-width: 1200px) {
  h5 {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 20px 0;
  }
}

a {
  font-weight: bold;
  text-decoration: none;
  color: #41B3FF;
}
a.underline {
  font-weight: normal;
  text-decoration: underline;
  color: inherit;
}
a.underline:hover {
  font-weight: bold;
}
a.p-text-silent {
  font-weight: normal;
}
a.p-text-silent:hover {
  text-decoration: underline;
}

p {
  margin: 0 0 20px 0;
}

.p-text-silent {
  font-size: 14px;
  color: #A5A3B8;
}

/* Utilities */
.p-color {
  /* Primary */
  /* Secondary */
  /* State */
  /* Gray */
}
.p-color-primary {
  color: #817CCD;
}
.p-color-secondary {
  color: #41B3FF;
}
.p-color-white {
  color: #ffffff;
}
.p-color-danger {
  color: #F65F5F;
}
.p-color-warning {
  color: #FFCC56;
}
.p-color-success {
  color: #5BD985;
}
.p-color-gray-100 {
  color: #F6F7FB;
}
.p-color-gray-200 {
  color: #EDEDF1;
}
.p-color-gray-300 {
  color: #DBDAE3;
}
.p-color-gray-400 {
  color: #C9C8D4;
}
.p-color-gray-500 {
  color: #A5A3B8;
}
.p-color-gray-600 {
  color: #6E6B8B;
}
.p-color-gray-700 {
  color: #4E4B75;
}
.p-color-gray-800 {
  color: #373361;
}
.p-color-gray-900 {
  color: #1F1C41;
}
.p-bg {
  /* Primary */
  /* Secondary */
  /* State */
  /* Gray */
}
.p-bg-primary {
  background: #817CCD;
  border-color: #817CCD;
}
.p-bg-secondary {
  background: #41B3FF;
  border-color: #41B3FF;
}
.p-bg-white {
  background: #ffffff;
  border-color: #ffffff;
}
.p-bg-danger {
  background: #F65F5F;
  border-color: #F65F5F;
}
.p-bg-warning {
  background: #FFCC56;
  border-color: #FFCC56;
}
.p-bg-success {
  background: #5BD985;
  border-color: #5BD985;
}
.p-bg-gray-100 {
  background: #F6F7FB;
  border-color: #F6F7FB;
}
.p-bg-gray-200 {
  background: #EDEDF1;
  border-color: #EDEDF1;
}
.p-bg-gray-300 {
  background: #DBDAE3;
  border-color: #DBDAE3;
}
.p-bg-gray-400 {
  background: #C9C8D4;
  border-color: #C9C8D4;
}
.p-bg-gray-500 {
  background: #A5A3B8;
  border-color: #A5A3B8;
}
.p-bg-gray-600 {
  background: #6E6B8B;
  border-color: #6E6B8B;
}
.p-bg-gray-700 {
  background: #4E4B75;
  border-color: #4E4B75;
}
.p-bg-gray-800 {
  background: #373361;
  border-color: #373361;
}
.p-bg-gray-900 {
  background: #1F1C41;
  border-color: #1F1C41;
}

.p-text-align-left {
  text-align: left;
}
.p-text-align-center {
  text-align: center;
}
.p-text-align-right {
  text-align: right;
}

.p-min-vh-25 {
  min-height: 25vh;
}
.p-min-vh-50 {
  min-height: 50vh;
}
.p-min-vh-75 {
  min-height: 75vh;
}
.p-min-vh-100 {
  min-height: 100vh;
}
@media (min-width: 576px) {
  .p-min-vh-sm-25 {
    min-height: 25vh;
  }
  .p-min-vh-sm-50 {
    min-height: 50vh;
  }
  .p-min-vh-sm-75 {
    min-height: 75vh;
  }
  .p-min-vh-sm-100 {
    min-height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-min-vh-md-25 {
    min-height: 25vh;
  }
  .p-min-vh-md-50 {
    min-height: 50vh;
  }
  .p-min-vh-md-75 {
    min-height: 75vh;
  }
  .p-min-vh-md-100 {
    min-height: 100vh;
  }
}
@media (min-width: 992px) {
  .p-min-vh-lg-25 {
    min-height: 25vh;
  }
  .p-min-vh-lg-50 {
    min-height: 50vh;
  }
  .p-min-vh-lg-75 {
    min-height: 75vh;
  }
  .p-min-vh-lg-100 {
    min-height: 100vh;
  }
}
@media (min-width: 1200px) {
  .p-min-vh-xl-25 {
    min-height: 25vh;
  }
  .p-min-vh-xl-50 {
    min-height: 50vh;
  }
  .p-min-vh-xl-75 {
    min-height: 75vh;
  }
  .p-min-vh-xl-100 {
    min-height: 100vh;
  }
}
.p-min-vw-25 {
  min-width: 25vw;
}
.p-min-vw-50 {
  min-width: 50vw;
}
.p-min-vw-75 {
  min-width: 75vw;
}
.p-min-vw-100 {
  min-width: 100vw;
}
@media (min-width: 576px) {
  .p-min-vw-sm-25 {
    min-width: 25vw;
  }
  .p-min-vw-sm-50 {
    min-width: 50vw;
  }
  .p-min-vw-sm-75 {
    min-width: 75vw;
  }
  .p-min-vw-sm-100 {
    min-width: 100vw;
  }
}
@media (min-width: 768px) {
  .p-min-vw-md-25 {
    min-width: 25vw;
  }
  .p-min-vw-md-50 {
    min-width: 50vw;
  }
  .p-min-vw-md-75 {
    min-width: 75vw;
  }
  .p-min-vw-md-100 {
    min-width: 100vw;
  }
}
@media (min-width: 992px) {
  .p-min-vw-lg-25 {
    min-width: 25vw;
  }
  .p-min-vw-lg-50 {
    min-width: 50vw;
  }
  .p-min-vw-lg-75 {
    min-width: 75vw;
  }
  .p-min-vw-lg-100 {
    min-width: 100vw;
  }
}
@media (min-width: 1200px) {
  .p-min-vw-xl-25 {
    min-width: 25vw;
  }
  .p-min-vw-xl-50 {
    min-width: 50vw;
  }
  .p-min-vw-xl-75 {
    min-width: 75vw;
  }
  .p-min-vw-xl-100 {
    min-width: 100vw;
  }
}
.p-min-h-25 {
  min-height: 25%;
}
.p-min-h-50 {
  min-height: 50%;
}
.p-min-h-75 {
  min-height: 75%;
}
.p-min-h-100 {
  min-height: 100%;
}
@media (min-width: 576px) {
  .p-min-h-sm-25 {
    min-height: 25%;
  }
  .p-min-h-sm-50 {
    min-height: 50%;
  }
  .p-min-h-sm-75 {
    min-height: 75%;
  }
  .p-min-h-sm-100 {
    min-height: 100%;
  }
}
@media (min-width: 768px) {
  .p-min-h-md-25 {
    min-height: 25%;
  }
  .p-min-h-md-50 {
    min-height: 50%;
  }
  .p-min-h-md-75 {
    min-height: 75%;
  }
  .p-min-h-md-100 {
    min-height: 100%;
  }
}
@media (min-width: 992px) {
  .p-min-h-lg-25 {
    min-height: 25%;
  }
  .p-min-h-lg-50 {
    min-height: 50%;
  }
  .p-min-h-lg-75 {
    min-height: 75%;
  }
  .p-min-h-lg-100 {
    min-height: 100%;
  }
}
@media (min-width: 1200px) {
  .p-min-h-xl-25 {
    min-height: 25%;
  }
  .p-min-h-xl-50 {
    min-height: 50%;
  }
  .p-min-h-xl-75 {
    min-height: 75%;
  }
  .p-min-h-xl-100 {
    min-height: 100%;
  }
}
.p-min-w-25 {
  min-width: 25%;
}
.p-min-w-50 {
  min-width: 50%;
}
.p-min-w-75 {
  min-width: 75%;
}
.p-min-w-100 {
  min-width: 100%;
}
@media (min-width: 576px) {
  .p-min-w-sm-25 {
    min-width: 25%;
  }
  .p-min-w-sm-50 {
    min-width: 50%;
  }
  .p-min-w-sm-75 {
    min-width: 75%;
  }
  .p-min-w-sm-100 {
    min-width: 100%;
  }
}
@media (min-width: 768px) {
  .p-min-w-md-25 {
    min-width: 25%;
  }
  .p-min-w-md-50 {
    min-width: 50%;
  }
  .p-min-w-md-75 {
    min-width: 75%;
  }
  .p-min-w-md-100 {
    min-width: 100%;
  }
}
@media (min-width: 992px) {
  .p-min-w-lg-25 {
    min-width: 25%;
  }
  .p-min-w-lg-50 {
    min-width: 50%;
  }
  .p-min-w-lg-75 {
    min-width: 75%;
  }
  .p-min-w-lg-100 {
    min-width: 100%;
  }
}
@media (min-width: 1200px) {
  .p-min-w-xl-25 {
    min-width: 25%;
  }
  .p-min-w-xl-50 {
    min-width: 50%;
  }
  .p-min-w-xl-75 {
    min-width: 75%;
  }
  .p-min-w-xl-100 {
    min-width: 100%;
  }
}
.p-max-vh-25 {
  max-height: 25vh;
}
.p-max-vh-50 {
  max-height: 50vh;
}
.p-max-vh-75 {
  max-height: 75vh;
}
.p-max-vh-100 {
  max-height: 100vh;
}
@media (min-width: 576px) {
  .p-max-vh-sm-25 {
    max-height: 25vh;
  }
  .p-max-vh-sm-50 {
    max-height: 50vh;
  }
  .p-max-vh-sm-75 {
    max-height: 75vh;
  }
  .p-max-vh-sm-100 {
    max-height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-max-vh-md-25 {
    max-height: 25vh;
  }
  .p-max-vh-md-50 {
    max-height: 50vh;
  }
  .p-max-vh-md-75 {
    max-height: 75vh;
  }
  .p-max-vh-md-100 {
    max-height: 100vh;
  }
}
@media (min-width: 992px) {
  .p-max-vh-lg-25 {
    max-height: 25vh;
  }
  .p-max-vh-lg-50 {
    max-height: 50vh;
  }
  .p-max-vh-lg-75 {
    max-height: 75vh;
  }
  .p-max-vh-lg-100 {
    max-height: 100vh;
  }
}
@media (min-width: 1200px) {
  .p-max-vh-xl-25 {
    max-height: 25vh;
  }
  .p-max-vh-xl-50 {
    max-height: 50vh;
  }
  .p-max-vh-xl-75 {
    max-height: 75vh;
  }
  .p-max-vh-xl-100 {
    max-height: 100vh;
  }
}
.p-max-vw-25 {
  max-height: 25vw;
}
.p-max-vw-50 {
  max-height: 50vw;
}
.p-max-vw-75 {
  max-height: 75vw;
}
.p-max-vw-100 {
  max-height: 100vw;
}
@media (min-width: 576px) {
  .p-max-vw-sm-25 {
    max-height: 25vw;
  }
  .p-max-vw-sm-50 {
    max-height: 50vw;
  }
  .p-max-vw-sm-75 {
    max-height: 75vw;
  }
  .p-max-vw-sm-100 {
    max-height: 100vw;
  }
}
@media (min-width: 768px) {
  .p-max-vw-md-25 {
    max-height: 25vw;
  }
  .p-max-vw-md-50 {
    max-height: 50vw;
  }
  .p-max-vw-md-75 {
    max-height: 75vw;
  }
  .p-max-vw-md-100 {
    max-height: 100vw;
  }
}
@media (min-width: 992px) {
  .p-max-vw-lg-25 {
    max-height: 25vw;
  }
  .p-max-vw-lg-50 {
    max-height: 50vw;
  }
  .p-max-vw-lg-75 {
    max-height: 75vw;
  }
  .p-max-vw-lg-100 {
    max-height: 100vw;
  }
}
@media (min-width: 1200px) {
  .p-max-vw-xl-25 {
    max-height: 25vw;
  }
  .p-max-vw-xl-50 {
    max-height: 50vw;
  }
  .p-max-vw-xl-75 {
    max-height: 75vw;
  }
  .p-max-vw-xl-100 {
    max-height: 100vw;
  }
}
.p-max-h-25 {
  max-height: 25%;
}
.p-max-h-50 {
  max-height: 50%;
}
.p-max-h-75 {
  max-height: 75%;
}
.p-max-h-100 {
  max-height: 100%;
}
@media (min-width: 576px) {
  .p-max-h-sm-25 {
    max-height: 25%;
  }
  .p-max-h-sm-50 {
    max-height: 50%;
  }
  .p-max-h-sm-75 {
    max-height: 75%;
  }
  .p-max-h-sm-100 {
    max-height: 100%;
  }
}
@media (min-width: 768px) {
  .p-max-h-md-25 {
    max-height: 25%;
  }
  .p-max-h-md-50 {
    max-height: 50%;
  }
  .p-max-h-md-75 {
    max-height: 75%;
  }
  .p-max-h-md-100 {
    max-height: 100%;
  }
}
@media (min-width: 992px) {
  .p-max-h-lg-25 {
    max-height: 25%;
  }
  .p-max-h-lg-50 {
    max-height: 50%;
  }
  .p-max-h-lg-75 {
    max-height: 75%;
  }
  .p-max-h-lg-100 {
    max-height: 100%;
  }
}
@media (min-width: 1200px) {
  .p-max-h-xl-25 {
    max-height: 25%;
  }
  .p-max-h-xl-50 {
    max-height: 50%;
  }
  .p-max-h-xl-75 {
    max-height: 75%;
  }
  .p-max-h-xl-100 {
    max-height: 100%;
  }
}
.p-max-w-25 {
  max-height: 25%;
}
.p-max-w-50 {
  max-height: 50%;
}
.p-max-w-75 {
  max-height: 75%;
}
.p-max-w-100 {
  max-height: 100%;
}
@media (min-width: 576px) {
  .p-max-w-sm-25 {
    max-height: 25%;
  }
  .p-max-w-sm-50 {
    max-height: 50%;
  }
  .p-max-w-sm-75 {
    max-height: 75%;
  }
  .p-max-w-sm-100 {
    max-height: 100%;
  }
}
@media (min-width: 768px) {
  .p-max-w-md-25 {
    max-height: 25%;
  }
  .p-max-w-md-50 {
    max-height: 50%;
  }
  .p-max-w-md-75 {
    max-height: 75%;
  }
  .p-max-w-md-100 {
    max-height: 100%;
  }
}
@media (min-width: 992px) {
  .p-max-w-lg-25 {
    max-height: 25%;
  }
  .p-max-w-lg-50 {
    max-height: 50%;
  }
  .p-max-w-lg-75 {
    max-height: 75%;
  }
  .p-max-w-lg-100 {
    max-height: 100%;
  }
}
@media (min-width: 1200px) {
  .p-max-w-xl-25 {
    max-height: 25%;
  }
  .p-max-w-xl-50 {
    max-height: 50%;
  }
  .p-max-w-xl-75 {
    max-height: 75%;
  }
  .p-max-w-xl-100 {
    max-height: 100%;
  }
}
.p-vh-25 {
  height: 25vh;
}
.p-vh-50 {
  height: 50vh;
}
.p-vh-75 {
  height: 75vh;
}
.p-vh-100 {
  height: 100vh;
}
@media (min-width: 576px) {
  .p-vh-sm-25 {
    height: 25vh;
  }
  .p-vh-sm-50 {
    height: 50vh;
  }
  .p-vh-sm-75 {
    height: 75vh;
  }
  .p-vh-sm-100 {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-vh-md-25 {
    height: 25vh;
  }
  .p-vh-md-50 {
    height: 50vh;
  }
  .p-vh-md-75 {
    height: 75vh;
  }
  .p-vh-md-100 {
    height: 100vh;
  }
}
@media (min-width: 992px) {
  .p-vh-lg-25 {
    height: 25vh;
  }
  .p-vh-lg-50 {
    height: 50vh;
  }
  .p-vh-lg-75 {
    height: 75vh;
  }
  .p-vh-lg-100 {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .p-vh-xl-25 {
    height: 25vh;
  }
  .p-vh-xl-50 {
    height: 50vh;
  }
  .p-vh-xl-75 {
    height: 75vh;
  }
  .p-vh-xl-100 {
    height: 100vh;
  }
}
.p-vw-25 {
  width: 25vw;
}
.p-vw-50 {
  width: 50vw;
}
.p-vw-75 {
  width: 75vw;
}
.p-vw-100 {
  width: 100vw;
}
@media (min-width: 576px) {
  .p-vw-sm-25 {
    width: 25vw;
  }
  .p-vw-sm-50 {
    width: 50vw;
  }
  .p-vw-sm-75 {
    width: 75vw;
  }
  .p-vw-sm-100 {
    width: 100vw;
  }
}
@media (min-width: 768px) {
  .p-vw-md-25 {
    width: 25vw;
  }
  .p-vw-md-50 {
    width: 50vw;
  }
  .p-vw-md-75 {
    width: 75vw;
  }
  .p-vw-md-100 {
    width: 100vw;
  }
}
@media (min-width: 992px) {
  .p-vw-lg-25 {
    width: 25vw;
  }
  .p-vw-lg-50 {
    width: 50vw;
  }
  .p-vw-lg-75 {
    width: 75vw;
  }
  .p-vw-lg-100 {
    width: 100vw;
  }
}
@media (min-width: 1200px) {
  .p-vw-xl-25 {
    width: 25vw;
  }
  .p-vw-xl-50 {
    width: 50vw;
  }
  .p-vw-xl-75 {
    width: 75vw;
  }
  .p-vw-xl-100 {
    width: 100vw;
  }
}
.p-h-25 {
  height: 25%;
}
.p-h-50 {
  height: 50%;
}
.p-h-75 {
  height: 75%;
}
.p-h-100 {
  height: 100%;
}
@media (min-width: 576px) {
  .p-h-sm-25 {
    height: 25%;
  }
  .p-h-sm-50 {
    height: 50%;
  }
  .p-h-sm-75 {
    height: 75%;
  }
  .p-h-sm-100 {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .p-h-md-25 {
    height: 25%;
  }
  .p-h-md-50 {
    height: 50%;
  }
  .p-h-md-75 {
    height: 75%;
  }
  .p-h-md-100 {
    height: 100%;
  }
}
@media (min-width: 992px) {
  .p-h-lg-25 {
    height: 25%;
  }
  .p-h-lg-50 {
    height: 50%;
  }
  .p-h-lg-75 {
    height: 75%;
  }
  .p-h-lg-100 {
    height: 100%;
  }
}
@media (min-width: 1200px) {
  .p-h-xl-25 {
    height: 25%;
  }
  .p-h-xl-50 {
    height: 50%;
  }
  .p-h-xl-75 {
    height: 75%;
  }
  .p-h-xl-100 {
    height: 100%;
  }
}
.p-w-25 {
  width: 25%;
}
.p-w-50 {
  width: 50%;
}
.p-w-75 {
  width: 75%;
}
.p-w-100 {
  width: 100%;
}
@media (min-width: 576px) {
  .p-w-sm-25 {
    width: 25%;
  }
  .p-w-sm-50 {
    width: 50%;
  }
  .p-w-sm-75 {
    width: 75%;
  }
  .p-w-sm-100 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .p-w-md-25 {
    width: 25%;
  }
  .p-w-md-50 {
    width: 50%;
  }
  .p-w-md-75 {
    width: 75%;
  }
  .p-w-md-100 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .p-w-lg-25 {
    width: 25%;
  }
  .p-w-lg-50 {
    width: 50%;
  }
  .p-w-lg-75 {
    width: 75%;
  }
  .p-w-lg-100 {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .p-w-xl-25 {
    width: 25%;
  }
  .p-w-xl-50 {
    width: 50%;
  }
  .p-w-xl-75 {
    width: 75%;
  }
  .p-w-xl-100 {
    width: 100%;
  }
}
.p-border-radius {
  border-radius: 10px;
}
.p-border-none {
  border: none;
}
.p-of-hidden {
  overflow: hidden;
}
.p-of-visible {
  overflow: visible;
}
.p-shadow {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
}

/*# sourceMappingURL=theme.css.map */
